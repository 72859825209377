import { Link } from 'react-router-dom';

import section1ico from 'assets/images/svg/section_1.svg';
import section2ico from 'assets/images/svg/section_2.svg';
import section3ico from 'assets/images/svg/section_3.svg';
import section4ico from 'assets/images/svg/section_4.svg';
import section5ico from 'assets/images/svg/section_5.svg';
import section6ico from 'assets/images/svg/section_6.svg';

export const Home = () => {
    return (
        <div className="content-container">

            <div className="sections-block">
                <div className="sections-block_item">
                    <div className="icon">
                        <img src={section1ico} alt="Getting started" />
                    </div>
                    <div className="title">
                        Getting started
                    </div>

                    <div className="list">
                        <ul>
                            <li><Link to="/section/page/">Create account</Link></li>
                            <li><Link to="/section/page/">Set up table plan</Link></li>
                            <li><Link to="/section/page/">Add devices</Link></li>
                            <li><Link to="/section/page/">Create articles</Link></li>
                            <li><Link to="/section/page/">Create modifiers</Link></li>
                        </ul>
                    </div>

                    <div className="more">
                        <Link to="/section">See more <i className="air-bo-2-arrow-right" /></Link>
                    </div>
                </div>

                <div className="sections-block_item">
                    <div className="icon">
                        <img src={section2ico} alt="Account" />
                    </div>
                    <div className="title">
                        Account
                    </div>

                    <div className="list">
                        <ul>
                            <li><Link to="/section/page/">Verify account</Link></li>
                            <li><Link to="/section/page/">Access account</Link></li>
                            <li><Link to="/section/page/">Update personal details</Link></li>
                            <li><Link to="/section/page/">Update business details</Link></li>
                            <li><Link to="/section/page/">Change subscription plan</Link></li>
                        </ul>
                    </div>

                    <div className="more">
                        <Link to="/section">See more <i className="air-bo-2-arrow-right" /></Link>
                    </div>
                </div>

                <div className="sections-block_item">
                    <div className="icon">
                        <img src={section3ico} alt="Products" />
                    </div>
                    <div className="title">
                        Products
                    </div>

                    <div className="list">
                        <ul>
                            <li><Link to="/section/page/">Add articles</Link></li>
                            <li><Link to="/section/page/">Create departments</Link></li>
                            <li><Link to="/section/page/">Change order on POS</Link></li>
                            <li><Link to="/section/page/">Create a menu</Link></li>
                            <li><Link to="/section/page/">Create a combi deal</Link></li>
                        </ul>
                    </div>

                    <div className="more">
                        <Link to="/section">See more <i className="air-bo-2-arrow-right" /></Link>
                    </div>
                </div>

                <div className="sections-block_item">
                    <div className="icon">
                        <img src={section4ico} alt="Equipment" />
                    </div>
                    <div className="title">
                        Equipment
                    </div>

                    <div className="list">
                        <ul>
                            <li><Link to="/section/page/">Add a printer</Link></li>
                            <li><Link to="/section/page/">Add a tablet</Link></li>
                            <li><Link to="/section/page/">Add other devices</Link></li>
                            <li><Link to="/section/page/">Printing tickets</Link></li>
                            <li><Link to="/section/page/">Troubleshooting</Link></li>
                        </ul>
                    </div>

                    <div className="more">
                        <Link to="/section">See more <i className="air-bo-2-arrow-right" /></Link>
                    </div>
                </div>

                <div className="sections-block_item">
                    <div className="icon">
                        <img src={section5ico} alt="Reporting" />
                    </div>
                    <div className="title">
                        Reporting
                    </div>

                    <div className="list">
                        <ul>
                            <li><Link to="/section/page/">Daily reports</Link></li>
                            <li><Link to="/section/page/">Financial reports</Link></li>
                            <li><Link to="/section/page/">User reports</Link></li>
                            <li><Link to="/section/page/">Create a report</Link></li>
                            <li><Link to="/section/page/">Export report</Link></li>
                        </ul>
                    </div>

                    <div className="more">
                        <Link to="/section">See more <i className="air-bo-2-arrow-right" /></Link>
                    </div>
                </div>

                <div className="sections-block_item">
                    <div className="icon">
                        <img src={section6ico} alt="Other" />
                    </div>
                    <div className="title">
                        Other
                    </div>

                    <div className="list">
                        <ul>
                            <li><Link to="/section/page/">Personalization</Link></li>
                            <li><Link to="/section/page/">Security</Link></li>
                            <li><Link to="/section/page/">Terms & Conditions</Link></li>
                            <li><Link to="/section/page/">VAT rules in your country</Link></li>
                            <li><Link to="/section/page/">Other</Link></li>
                        </ul>
                    </div>

                    <div className="more">
                        <Link to="/section">See more</Link><i className="" />
                    </div>
                </div>
            </div>

            <div className="page-separator"></div>

            <div className="faq-block">
                <div className="faq-block_title">Most commonly asked questions</div>

                <div className="faq-block_list">
                    <div className="faq-block_list-item opened">
                        <div className="question">
                            <div className="question-text">How I can reset my password?</div>
                            <div className="question-toggler"><i className="air-bo-2-arrow-down" /></div>
                        </div>
                        <div className="answer">
                            <div className="content">
                                <p>If you forgot your password, please follow these steps to reset it:</p>
                                <ul>
                                    <li>Visit the Password Reset page.</li>
                                    <li>Enter the email address associated with your Untill account and select Reset password to receive an email.</li>
                                    <li>From the email, select Reset password to open a window where you'll enter a new password. </li>
                                    <li>Enter your new password in the Choose A Password and Confirm Password fields, then select Update password.</li>
                                </ul>
                                <p>You can now sign in with your new password.</p>
                            </div>
                        </div>
                    </div>

                    <div className="faq-block_list-item">
                        <div className="question">
                            <div className="question-text">How I can reset my password?</div>
                            <div className="question-toggler"><i className="air-bo-2-arrow-down" /></div>
                        </div>
                        <div className="answer content">
                            <div className="content">
                                <p>If you forgot your password, please follow these steps to reset it:</p>
                                <ul>
                                    <li>Visit the Password Reset page.</li>
                                    <li>Enter the email address associated with your Untill account and select Reset password to receive an email.</li>
                                    <li>From the email, select Reset password to open a window where you'll enter a new password. </li>
                                    <li>Enter your new password in the Choose A Password and Confirm Password fields, then select Update password.</li>
                                </ul>
                                <p>You can now sign in with your new password.</p>
                            </div>
                        </div>
                    </div>

                    <div className="faq-block_list-item">
                        <div className="question">
                            <div className="question-text">How I can reset my password?</div>
                            <div className="question-toggler"><i className="air-bo-2-arrow-down" /></div>
                        </div>
                        <div className="answer content">
                            <div className="content">
                                <p>If you forgot your password, please follow these steps to reset it:</p>
                                <ul>
                                    <li>Visit the Password Reset page.</li>
                                    <li>Enter the email address associated with your Untill account and select Reset password to receive an email.</li>
                                    <li>From the email, select Reset password to open a window where you'll enter a new password. </li>
                                    <li>Enter your new password in the Choose A Password and Confirm Password fields, then select Update password.</li>
                                </ul>
                                <p>You can now sign in with your new password.</p>
                            </div>
                        </div>
                    </div>

                    <div className="faq-block_list-item">
                        <div className="question">
                            <div className="question-text">How I can reset my password?</div>
                            <div className="question-toggler"><i className="air-bo-2-arrow-down" /></div>
                        </div>
                        <div className="answer content">
                            <div className="content">
                                <p>If you forgot your password, please follow these steps to reset it:</p>
                                <ul>
                                    <li>Visit the Password Reset page.</li>
                                    <li>Enter the email address associated with your Untill account and select Reset password to receive an email.</li>
                                    <li>From the email, select Reset password to open a window where you'll enter a new password. </li>
                                    <li>Enter your new password in the Choose A Password and Confirm Password fields, then select Update password.</li>
                                </ul>
                                <p>You can now sign in with your new password.</p>
                            </div>
                        </div>
                    </div>

                    <div className="faq-block_list-item">
                        <div className="question">
                            <div className="question-text">How I can reset my password?</div>
                            <div className="question-toggler"><i className="air-bo-2-arrow-down" /></div>
                        </div>
                        <div className="answer content">
                            <div className="content">
                                <p>If you forgot your password, please follow these steps to reset it:</p>
                                <ul>
                                    <li>Visit the Password Reset page.</li>
                                    <li>Enter the email address associated with your Untill account and select Reset password to receive an email.</li>
                                    <li>From the email, select Reset password to open a window where you'll enter a new password. </li>
                                    <li>Enter your new password in the Choose A Password and Confirm Password fields, then select Update password.</li>
                                </ul>
                                <p>You can now sign in with your new password.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}