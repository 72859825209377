export const Search = () => {
    return (
        <div className="search-block">
            <div className="search-block_title">
                Hello, how can we help?
            </div>
            <div className="search-block_input">
                <input type="text" placeholder="Search..." />
            </div>
        </div>
    );
}