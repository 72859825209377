import { Link } from 'react-router-dom';

export const Page = () => {
    return <div className="content-container">
        <div className="breadcrumbs">
            <Link to={'/'}>UnTill Help Center</Link>
            <span className="separator">{'>'}</span>
            <Link to={'/section/'}>Manage account</Link>
            <span className="separator">{'>'}</span>
            <span>Manage account</span>
        </div>

        <div className="content">
            <h1>Reset password</h1>

            <p>If you forgot your password, please follow these steps to reset it:</p>
            <ol>
                <li>Visit the <b>Password Reset</b> page.</li>
                <li>Enter the email address associated with your Untill account and select <b>Password Reset</b> to receive an email.</li>
                <li>From the email, select <b>Password Reset</b> to open a window where you'll enter a new password. </li>
                <li>Enter your new password in the Choose A Password and Confirm Password fields, then select Update password.</li>
            </ol>
            <p>You can now sign in with your new password.</p>
        </div>
    </div>;
}