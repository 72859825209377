import { Outlet } from "react-router-dom";

import { Header, Search, Body, Footer } from 'components/common';

export const Layout = () => {
    return <div className="main-layout">
        <Header />
        <Search />

        <Body>
            <Outlet />
        </Body>
        <Footer />

    </div>
}