import ModalLayout from 'components/views/ModalLayout';
import { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';

export const Footer = () => {

    const [showForm, setShowForm] = useState(false);

    const handleClick = () => {
        setShowForm(true);
    }

    const handleClose = () => {
        setShowForm(false);
    }

    return (
        <>
            <div className="layout-footer">
                <div className="layout-footer_text">
                    Can't find what you're looking for?
                </div>

                <div className="layout-footer_button">
                    <div className="btn" onClick={handleClick}>Contact us</div>
                </div>
            </div>

            {showForm ? (
                <ModalLayout
                    size="small"
                >
                    <div className="form-header">
                        Get in touch
                        <div className="form-header_close" onClick={handleClose}>
                            <i className="air-bo-2-cross" />
                        </div>
                    </div>

                    <Form>
                        <Form.Item name="name">
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item name="email">
                            <Input placeholder="E-mail" />
                        </Form.Item>
                        <Form.Item name="topic">
                            <Select placeholder="Select topic" >
                                <Select.Option value={1}>Topic 1</Select.Option>
                                <Select.Option value={2}>Topic 2</Select.Option>
                                <Select.Option value={3}>Topic 2</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="description">
                            <Input.TextArea placeholder="Describe your problem" resize={false} />
                        </Form.Item>

                        <Button block type="primary" htmlType="submit">
                            Send message
                        </Button>
                    </Form>
                </ModalLayout>
            ) : null}
        </>
    );
}