import { Breadcrumbs } from 'components/common';
import { Link } from 'react-router-dom';

import icon from 'assets/images/svg/section_1.svg'

export const Section = () => {
    return (
        <div className="content-container with-sidebar">
            <div className="sidebar">
                <Link to="/section/">Getting started</Link>
                <Link to="/section/" className="active">Account</Link>
                <Link to="/section/">Products</Link>
                <Link to="/section/">Devices</Link>
                <Link to="/section/">Reporting</Link>
                <Link to="/section/">Other</Link>
            </div>

            <div content="content">
                <div className="breadcrumbs">
                    <Link to={'/'}>UnTill Help Center</Link>
                    <span className="separator">{'>'}</span>
                    <span>Manage account</span>
                </div>

                <div className="section-header">
                    <div className="section-header_icon">
                        <img src={icon} alt={icon} />
                    </div>
                    <div className="section-header_text">
                        Account
                    </div>
                </div>

                <div className="section-block">
                    <div className="section-block_header">
                        Verify account
                    </div>

                    <div className="section-block_list">
                        <ul>
                            <li>
                                <Link to="/section/page">The link in my verification email is missing, expired, or invalid</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Using and managing security keys</Link>
                            </li>
                            <li>
                                <Link to="/section/page">What type of photo ID can I provide?</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Why didn't I receive a verification email?</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Why is my vault withdrawal pending?</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Why isn't my device recognized?</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="section-block">
                    <div className="section-block_header">
                        Access account
                    </div>

                    <div className="section-block_list">
                        <ul>
                            <li>
                                <Link to="/section/page">I can't remember my password</Link>
                            </li>
                            <li>
                                <Link to="/section/page">2-step verification troubleshooting</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Lost email access</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Reset my password</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="section-block">
                    <div className="section-block_header">
                        Update personal details
                    </div>

                    <div className="section-block_list">
                        <ul>
                            <li>
                                <Link to="/section/page">Update my profile picture</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Update my address</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Update  my name / surname</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Update my payment method</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="section-block">
                    <div className="section-block_header">
                        Update business details
                    </div>

                    <div className="section-block_list">
                        <ul>
                            <li>
                                <Link to="/section/page">My business address has changed</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Update my VAT tax levels</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Update business contact information</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="section-block">
                    <div className="section-block_header">
                        Subscription
                    </div>

                    <div className="section-block_list">
                        <ul>
                            <li>
                                <Link to="/section/page">Free trial</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Billing plans</Link>
                            </li>
                            <li>
                                <Link to="/section/page">Update my subscription plan</Link>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
}