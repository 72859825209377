import { Routes, Route } from "react-router-dom";

import { Layout, Home, Section, Page, NoMatch } from './views';

const Root = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path=":section" element={<Section />} />
                <Route path=":section/:element" element={<Page />} />

                <Route path="*" element={<NoMatch />} />
            </Route>


        </Routes>
    );
}

export default Root;