import logo from 'assets/images/svg/top-logo.svg';

export const Header = () => {
    return (
        <>
            <div className="layout-header">
                <div className="layout-header_logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="layout-header_title">
                    Knowledge base
                </div>
            </div>
        </>
    );
}